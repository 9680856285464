export default {
  COMMON: {
    SUBTITLE: "Let’s renew your company",
    TITLE: "What happens next",
    CLICK: "Or click ",
    HERE: "here",
    WANT_CLOSE: " if you want to close this company.",
    BTN_START: "Let’s start",
    BANNER_1:
      'Costs to file this change start from <strong>{{{currency}}} {{{price}}} + VAT</strong>. Select "{{{button}}}" to add this item to your cart. Then go to the Dashboard, click "View cart" to pay and submit.',
    ADDITIONAL_FEES:
      "<strong>Additional fees</strong> might apply if you need to update certain details with the ADGM Registrar.<br><br>Click on the field to update the details and view the corresponding fees (if any). All changes made will be added to your cart on the Dashboard, where you can pay and submit the updates.<br><br>Please note: all renewal tasks must be completed before submitting the application.",
  },
  WHAT_HAPPENS_TECH_LICENCE: {
    STEP_1: {
      // TODO fix second link
      TOOLTIP_1:
        'It is important to complete the renewal process ahead of the expiry date of the current commercial licence to avoid fines for the company and penalties for its directors. For more information, click <a target="_blank" href="https://help.clara.co/en/articles/6235450-what-are-the-penalties-for-late-renewal">here</a>.<br><br>You can see the core list of information and documents you will need to complete the renewal process <a target="_blank" href="https://help.clara.co/en/articles/6235450-what-are-the-penalties-for-late-renewal">here</a>. Additional documents may be needed in some circumstances.',
      TOOLTIP_2:
        'If you do not wish to renew, we strongly recommend that you close your ADGM company with Clara’s support. Doing nothing may result in fines and penalties. For more information on the process to close your company, click <a target="_blank" href="https://app.intercom.com/a/apps/j71m5966/articles/articles/6493944/show">here</a>.',
      CONTENT_1: "Next steps",
      CONTENT_2:
        "<ol style='padding-left: 20px'><li style='padding-bottom: 10px'>Complete the questionnaire to assess your company's licence classification for renewal.</li><li style='padding-bottom: 10px'>Once Clara confirms the classification, you will be notified to come back to the platform and follow the task list to confirm the company and stakeholder details.</li><li>If your lease has expired (or will soon), you will need to provide a new lease.</li></ol>",
      CONTENT_3: "Costs",
      CONTENT_4:
        "The renewal fee depends on the licence classification. We will advise you once the classification is confirmed. Additional fees apply if you need to make changes to the information for your company or you will use Clara's Nominee Authorised Signatory.",
    },
  },
  WHATHAPPENS_HOLDING_COMPANY: {
    STEP_1: {
      TOOLTIP_1:
        'It is important to complete the renewal process ahead of your commercial licence expiry date to avoid fines for the company and penalties for its directors. For more information, click <a target="_blank" href="https://help.clara.co/en/articles/6235450-what-are-the-penalties-for-late-renewal">here</a>.<br><br>You can see the core list of information and documents you will need to complete the renewal process <a target="_blank" href="">here</a>. Additional documents may be needed in some circumstances.<br><br>For more information on the services included in our renewal fee, click <a target="_blank" href="https://help.clara.co/en/articles/6235455-what-is-included-in-the-spv-renewal-fee">here</a>.',
      TOOLTIP_2:
        'If you need to amend your company\'s activities from a holding to an operational company, Clara can support you with this process. Fees apply.<br><br>If you do not wish to renew, we strongly recommend that you close your ADGM company with Clara’s support. Doing nothing may result in fines and penalties. For more information on the process to close your company, click <a target="_blank" href="https://app.intercom.com/a/apps/j71m5966/articles/articles/6493944/show">here</a>.',
      CONTENT_1: "Next steps",
      CONTENT_2:
        "To renew the licence you will need to follow the task list to confirm the company and stakeholder details.",
      CONTENT_3: "Costs",
      CONTENT_4:
        "The renewal fee of your ADGM SPV is <strong>{{{currency}}} {{amount}} + VAT</strong>. This includes {{{currency}}} {{amountFees}} ADGM renewal fees.",
      CONTENT_5:
        "Additional fees apply if you need to make changes to the information for your company or you will use Clara's Nominee Authorised Signatory.",
    },
  },
  CLOSE_DOWN_COMPANY: {
    STEP_1: {
      TITLE: "Close down a company in ADGM",
      SUBTITLE: "{{{companyName}}} Strike Off",
      TOOLTIP_1:
        "In order to close down (or “voluntary strike off”) a company in ADGM, you will have to meet <strong>all</strong> 5 requirements listed. If this is not the case, in the next screen you will be able to send a request in to our team of expert to help you further.<br><br>Please note, if you don’t meet requirements 1, 2 or 3,  you will have to wait <strong>three months</strong> before you can qualify for closing down the company.",
      CONTENT_1:
        "Before proceeding, you’ll need to confirm that the company has not:",
      CONTENT_2:
        "<ol style='padding-left: 20px'><li style='padding-bottom: 10px'>Changed its name in the last 3 months.</li><li style='padding-bottom: 10px'>Traded or carried on business in the last 3 months.</li><li style='padding-bottom: 10px'>Disposed of property or rights in the last 3 months.</li><li style='padding-bottom: 10px'>Been (or continues to be) party to any Court Proceedings that have not been concluded.</li><li>Been placed into Administration, Liquidation or Receivership.</li></ol>",
    },
    STEP_2: {
      TITLE: "What happens next",
      SUBTITLE: "{{{companyName}}} Strike Off",
      CONTENT_1: "Next steps",
      CONTENT_2:
        "The costs to close down your company will be <strong>{{currency}} {{price}} + VAT</strong>. You must pay the fee and submit your request to Clara. If you have an office lease with more than 2 weeks validity, you will need to surrender the lease which costs <strong>{{currency}} {{price2}} + VAT</strong>. This is only applicable to Tech Licence and Operating companies.",
      CONTENT_3:
        "If you change your mind and decide to renew the licence instead, you can choose to <strong>Cancel strike off</strong> from the Dashboard. Once the strike off has been paid, the action cannot be undone.",
    },
  },
  REVIEW_COMPANY_DETAILS: {
    STEP_1: {
      // TODO fix link
      TOOLTIP_1:
        "<strong>Additional fees</strong> might apply if you need to update certain details with the ADGM Registrar.<br><br>Click on the field to update the details and view the corresponding fees (if any). All changes made will be added to your cart on the Dashboard, where you can pay and submit the updates.<br><br>Please note: all renewal tasks must be completed before submitting the application.",
    },
  },
  REVIEW_ADDRESS: {
    SPV_TOOLTIP_1:
      "<strong>Additional fees</strong> might apply if you need to update certain details with the ADGM Registrar.<br><br>Click on the field to update the details and view the corresponding fees (if any). All changes made will be added to your cart on the Dashboard, where you can pay and submit the updates..<br><br>Please note: all renewal tasks must be completed before submitting the application.",
    SPV_TOOLTIP_2:
      "The <strong>registered office address</strong> for your ADGM SPV is Clara’s registered office address. <br/><br/>The default address for the <strong>mailing address</strong> is the same as the registered office address. If you want to change this, we will file a change with ADGM and fees apply.<br/><br/>Clara maintains your statutory records, but the <strong>Accounting Records</strong> are your day-to-day financial accounts which you will maintain elsewhere. This can be in or outside of the UAE. If in the UAE, please provide a PO Box number",
    TL_TOOLTIP_1:
      'For contact and pricing information regarding office space on Al Maryah Island, click <a href="https://clara.co/terms-of-use/" target="_blank">here</a>',
    TL_TOOLTIP_2:
      "There is no charge to update your lease details during the renewal of your company.",
  },

  REVIEW_DETAILS_UBO: {
    COMMON: {
      // TODO fix link
      TOOLTIP_1:
        '<strong>Additional fees</strong> might apply if you need to update some of the details with the ADGM Registrar. Click <a target="_blank" href="https://help.clara.co/en/articles/6235450-what-are-the-penalties-for-late-renewal">here</a> to learn more about fees.<br><br>Click on the field to update to view the corresponding fees. To make a change, just edit the content and proceed. The change request will be added to your cart.<br><br>Please note: all the tasks related to the renewal of your company must be completed before submitting the application.',
      TOOLTIP_2:
        "Source of funds refers to the origin of money used for the new company’s current and future payments.<br><br>Source of wealth refers to how this person accrued their total wealth.",
    },
  },
  REVIEW_ADGM_AUTHORISED_SIGNATORY: {
    SUBTITLE: "Review ADGM Authorised Signatory",
    REVIEW_STEP: {
      TITLE: "Review and confirm your ADGM Authorised Signatories",
      DESCRIPTION:
        "Your current authorised signatories are listed below for your confirmation:",
      TOOLTIP:
        'All ADGM entities must always have an Authorised Signatory appointed who is a GCC National or UAE Resident. Click <a href="https://help.clara.co/en/articles/6385804-what-is-an-adgm-authorised-signatory" target="_blank">here</a> to read more on this role. <br/><br/>Clara can provide a nominee service for entities who do not have a GCC National or UAE Resident to fulfil this role. The fee is <strong>USD 700 annually</strong>. A fee of <strong>USD 550</strong> applies to remove our nominee and appoint your own replacement and this includes the USD 100 filing fee payable to ADGM. <br/>Click <a href="https://help.clara.co/en/articles/6230840-can-i-use-a-nominee-to-be-my-adgm-authorised-signatory" target="_blank">here</a> to learn about the nominee service.',
    },
    ASSIGN_OTHER_STEP: {
      TITLE: "Who is going to be appointed as second Authorised Signatory?",
      DESCRIPTION:
        "You can appoint someone who is not a director or shareholder but they must be a UAE Resident or GCC National.",
      TOOLTIP:
        "<b>Why do I need to appoint a secondary Authorised Signatory?</b> Click <a rel='noopener noreferrer' target='_blank' href='https://help.clara.co/en/articles/6230840-can-i-use-a-nominee-to-be-my-adgm-authorised-signatory' >here</a> to read more about the use of Clara’s nominee service.<br><br><b>Who can be the secondary Authorised Signatory?</b> You can choose anyone for this role, provided they are of good standing and have entered the UAE in the past two years. They do not need to be a director or shareholder. They will need to provide their personal information, identification documents and proof of entry into the UAE in the form of a UAE entry stamp in their passport or confirm the date they entered through e-gates.",
    },
    ASSIGN_STEP: {
      TITLE: "Who is going to be your ADGM Authorised Signatory?",
      DESCRIPTION:
        "You can appoint someone who is not a director or shareholder but they must be a UAE Resident or GCC National.",

      // TODO: AS MISSING LINK
      TOOLTIP_1:
        "All ADGM entities must always have an Authorised Signatory appointed who is a GCC National or UAE Resident. Click here to read more on this role. ",
      TOOLTIP_2:
        "If you are adding a new eligible stakeholder to be your Authorised Signatory, you must first complete their personal details tasks.",
    },
    CLARA_NOMINEE_STEP: {
      TITLE:
        "Please confirm you want to use Clara's Nominee Authorised Signatory Service",
      TOOLTIP_1:
        "To use this service, you must appoint a second Authorised Signatory. This person doesn't need to be a GCC Resident or National, they only need to have entered the UAE in the last two years.",
      TOOLTIP_2:
        "<b>Why do I need to appoint a secondary Authorised Signatory?</b> Click <a rel='noopener noreferrer' target='_blank' href='https://help.clara.co/en/articles/6230840-can-i-use-a-nominee-to-be-my-adgm-authorised-signatory' >here</a> to read more about the use of Clara’s nominee service.<br><br><b>Who can be the secondary Authorised Signatory?</b> You can choose anyone for this role, provided they are of good standing and have entered the UAE in the past two years. They do not need to be a director or shareholder. They will need to provide their personal information, identification documents and proof of entry into the UAE in the form of a UAE entry stamp in their passport or confirm the date they entered through e-gates.",
      TOOLTIP_3:
        "If you decide to opt out of this service, your total at checkout will be updated accordingly.",
    },
    CREATE_STAKEHOLDER_STEP: {
      TITLE:
        "Please confirm you want to use Clara's Nominee Authorised Signatory Service",
      TOOLTIP:
        "<b>Why do I need to appoint a secondary Authorised Signatory?</b> Click <a rel='noopener noreferrer' target='_blank' href='https://help.clara.co/en/articles/6230840-can-i-use-a-nominee-to-be-my-adgm-authorised-signatory' >here</a> to read more about the use of Clara’s nominee service.<br><br><b>Who can be the secondary Authorised Signatory?</b> You can choose anyone for this role, provided they are of good standing and have entered the UAE in the past two years. They do not need to be a director or shareholder. They will need to provide their personal information, identification documents and proof of entry into the UAE in the form of a UAE entry stamp in their passport or confirm the date they entered through e-gates.",
    },
    UAE_STEP: {
      TITLE: "What is their name?",
      TOOLTIP:
        "If this stakeholder entered via E- Gate, we will need to know the date of entry. Alternatively, we will need a copy of the UAE entry stamp in their passport.",
    },
    WHAT_CAPACITY_STEP: {
      TITLE: "In what capacity are the authorised signatories acting?",
      TOOLTIP:
        "If this stakeholder entered via E- Gate, we will need to know the date of entry. Alternatively, we will need a copy of the UAE entry stamp in their passport.",
    },
  },
  REVIEW_STAKEHOLDER_DETAILS: {
    CORPORATE: {
      COMMON: {
        TOOLTIP:
          "<strong>Additional fees</strong> might apply if you need to update certain details with the ADGM Registrar.<br><br>Click on the field to update the details and view the corresponding fees (if any). All changes made will be added to your cart on the Dashboard, where you can pay and submit the updates.<br><br>Please note: all renewal tasks must be completed before submitting the application.",
        SUBTITLE: `Review details for {{{stakeholderName}}}`,
      },
      DETAILS: {
        TITLE: "Review and confirm its details",
        DESCRIPTION: `{{{stakeholderName}}} is a <strong>shareholder</strong> of the company.`,
      },
      ADDRESS: {
        TITLE: "Review and confirm its address",
      },
      DIRECTORS: {
        TITLE: "Review and confirm its directors",
        DESCRIPTION:
          "Your current directors are listed below for your confirmation:",
      },
      SIGNATORY: {
        TOOLTIP:
          "A <strong>Corporate Signatory</strong> is any individual authorised by the Board of Directors to sign on behalf of this Corporate Stakeholder. Usually this is one of the Directors of the Corporate Stakeholder, though it could be a Shareholder, or a third party like a lawyer. The corporate signatory will be contacted if this Corporate Stakeholder is required to sign any future resolutions of the Company, and will be called upon to sign those resolutions on the Corporate Stakeholder’s behalf.",
        TITLE: "Review and confirm its corporate signatory",
      },
      PEP: {
        TOOLTIP:
          "A PEP is someone who is, or has been, entrusted with a prominent public function. Examples include heads of state, heads of government and senior politicians.",
        TITLE: "Review and confirm PEP",
      },
      SOURCE_OF_FUNDS: {
        TOOLTIP:
          "<strong>Source of funds</strong> refers to the origin of money used for this corporate stakeholder current and future payments. Click here to learn more. \n" +
          "<br/><br/>" +
          "<strong>Source of wealth</strong> refers to how this corporate stakeholder generates its revenue.  If this is revenue from its operations, please describe with detail. If revenue is generated from assets held, describe the assets and how they generate revenue. A company may not generate revenue and rely on shareholder investment. Click here to learn more.",
        TITLE: "Review and confirm its source of funds",
      },
    },
  },
  REVIEW_ADGM_DESIGNATED_CONTACT: {
    SUBTITLE: "Review ADGM designated contact",
    TOOLTIP_1: "<strong>Additional fees</strong> might apply if you need to update some of the details with the ADGM Registrar.<br><br>Click on the field to update the details and view the corresponding fees (if any). All changes made will be added to your cart on the Dashboard, where you can pay and submit the updates.<br><br>Please note: all renewal tasks must be completed before submitting the application.",
    TOOLTIP_2: "The ADGM Designated Contact is a contact person who can be reached regarding day-to-day operational matters for the company. If Clara is appointed as the Company Service Provider, ADGM will contact Clara as a first step. However, there may be instances where ADGM will want to contact the Company directly and therefore a contact must be selected.",
    REVIEW_STEP: {
      TITLE: "Review and confirm ADGM designated contact",
      DESCRIPTION:
        "Your current ADGM designated contact is listed below for your confirmation:",
      INPUT_LABEL: "Please confirm this is correct",
      INPUT_HELPER_TEXT: "You will be able to select a different stakeholder in the next screen.",
    },
    ASSIGN_STEP: {
      TITLE: "Select an ADGM designated contact",
    },
  },
};

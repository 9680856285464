
const US={
    MODAL_ADD_FOUNDER_AGREEMENT_MODAL_SUB_TITLE:"Founders' Agreement",
    MODAL_ADD_FOUNDER_AGREEMENT_MODAL_TITLE:"Do you have a Founders' Agreement?",
    MODAL_ADD_FOUNDER_AGREEMENT_MODAL_OPTION_GENERATE:'No, generate one now',
    MODAL_ADD_FOUNDER_AGREEMENT_MODAL_OPTION_UPLOAD:'Yes, upload it now',
    MODAL_ADD_FOUNDER_AGREEMENT_MODAL_OPTION_LATER:'Upload it later',
    MODAL_ADD_FOUNDER_AGREEMENT_STEP_SELECT_FOUNDER_TITLE: "Who are the founders involved in this Founders' Agreement?",
    MODAL_ADD_FOUNDER_AGREEMENT_MODAL_TOOLTIP: "Once uploaded, this document will be stored in your Data Room.",
    MODAL_ADD_FOUNDER_AGREEMENT_MODAL_UPLOAD_TITLE:"Upload document",
    
    MODAL_ADD_BUTTON:"Add",
    MODAL_ADD_FOUNDER_SERVICE_AGREEMENT_MODAL_SUB_TITLE:"Founder Services Agreement",
    MODAL_ADD_FOUNDER_SERVICE_AGREEMENT_MODAL_TITLE:"Do you have a Founder Services Agreement?",
    MODAL_ADD_FOUNDER_SERVICE_AGREEMENT_MODAL_OPTION_GENERATE:'No, generate one now',
    MODAL_ADD_FOUNDER_SERVICE_AGREEMENT_MODAL_OPTION_UPLOAD:'Yes, upload it now',
    MODAL_ADD_FOUNDER_SERVICE_AGREEMENT_MODAL_OPTION_LATER:'Upload it later',
    MODAL_ADD_FOUNDER_SERVICE_AGREEMENT_STEP_SELECT_FOUNDER_TITLE: "Who are the founders involved in this Founders' Agreement?",

    FIELD_NAME_LABEL_NAME: "company name",
    FIELD_NAME_LABEL_FULLNAME: "full name",
    FIELD_NAME_LABEL_PASSPORTNUMBER: "passport number",
    FIELD_NAME_LABEL_TITLE: "title",
    FIELD_NAME_LABEL_ADDRESS: "address",
    FIELD_NAME_LABEL_NATIONALITY: "nationality",
    FIELD_NAME_LABEL_COMPANY_NAME: "company name",
    FIELD_NAME_LABEL_REGISTEREDNUMBER: "company number",
    FIELD_NAME_LABEL_COMPANY_TYPE: "company type",
    FIELD_NAME_LABEL_FORMER_NAME: "former name",
    FIELD_NAME_LABEL_PREVIOUS_NAME: "previous name",
    FIELD_NAME_LABEL_OTHER_NATIONALITY: "other nationality",
    FIELD_NAME_LABEL_JURISDICTION: "jurisdiction",
    FIELD_NAME_LABEL_REGISTERED_OFFICE_ADDRESS: "registered office address",
    FIELD_NAME_LABEL_PHONE_NUMBER: "phone number",
    FIELD_NAME_LABEL_EMAIL: "email",
    FIELD_NAME_LABEL_PREVIOUSNAME: "previous name",
    FIELD_NAME_LABEL_PHONENUMBER: "phone number",
    FIELD_NAME_LABEL_REGISTEREDADDRESS: "registered address",
    FIELD_NAME_LABEL_MAILINGADDRESS: "mailing address",
    FIELD_NAME_LABEL_REGISTEREDADDRESS_MAILINGADDRESS: "registered and mailing address",
    
    FIELD_NAME_LABEL_DATEOFBIRTH: "date of birth",
    FIELD_NAME_LABEL_PROFESSION: "profession",
    FIELD_NAME_LABEL_COMPANYTYPE: "company type",
    FIELD_NAME_LABEL_ARABICNAME: "Arabic name",

    MODAL_PAYMENT_PINCAS_CATEGORY_STAKEHOLDER_ROLES: "Stakeholder {{{description}}} role",
    MODAL_PAYMENT_PINCAS_CATEGORY_STAKEHOLDER_DETAILS: "Update {{{description}}} details",
    MODAL_PAYMENT_PINCAS_CATEGORY_COMPANY_ITEM_COMPANY_RENEWAL: "Renew company",
    MODAL_PAYMENT_PINCAS_CATEGORY_COMPANY_DETAILS: "Update company details",
    MODAL_PAYMENT_PINCAS_CATEGORY_COMPANY_ITEM_COMPANY_STRIKE_OFF_ADGM: "ADGM Company Strike Off",
    MODAL_PAYMENT_PINCAS_CATEGORY_COMPANY_ITEM_COMPANY_STRIKE_OFF_CAYMAN: "CAYMAN Close down company",
    MODAL_PAYMENT_PINCAS_CATEGORY_COMPANY_DETAILS_ITEM_CHANGE_COMPANY_NAME: "Change {{{field}}} to {{{newValue}}}",
    MODAL_PAYMENT_PINCAS_CATEGORY_COMPANY_DETAILS_ITEM_CHANGE_COMPANY_CURRENCY: "Change company currency",
    MODAL_PAYMENT_PINCAS_CATEGORY_COMPANY_DETAILS_ITEM_CHANGE_COMPANY_REGISTERED_ADDRESS: "Change {{{field}}} to {{{newValue}}}",
    MODAL_PAYMENT_PINCAS_CATEGORY_COMPANY_DETAILS_ITEM_CHANGE_COMPANY_MAILING_ADDRESS: "Change {{{field}}} to {{{newValue}}}",
    MODAL_PAYMENT_PINCAS_CATEGORY_COMPANY_DETAILS_ITEM_CHANGE_COMPANY_ARTICLES_ASSOCIATION: "Change articles association",
    MODAL_PAYMENT_PINCAS_CATEGORY_COMPANY_DETAILS_ITEM_CHANGE_ARTICLES_ASSOCIATION: "Change articles association",
    MODAL_PAYMENT_PINCAS_CATEGORY_COMPANY_DETAILS_ITEM_CHANGE_COMPANY_BUSINESS_ACTIVITIES: "Change business activities to {{{newValue}}}",
    MODAL_PAYMENT_PINCAS_CATEGORY_COMPANY_DETAILS_ITEM_CHANGE_COMPANY_AUTHORIZED_SHARE_CAPITAL: "Change authorised share capital",
    MODAL_PAYMENT_PINCAS_CATEGORY_COMPANY_DETAILS_ITEM_CHANGE_COMPANY_AMEND_DATA_PROTECTION_CONTACT: "Change Data Protection Contact",
    MODAL_PAYMENT_PINCAS_CATEGORY_COMPANY_DETAILS_ITEM_CHANGE_COMPANY_AMEND_DESIGNATED_CONTACT: "Change Designated Contact",
    MODAL_PAYMENT_PINCAS_CATEGORY_COMPANY_DETAILS_ITEM_CHANGE_COMPANY_AMEND_AUTHORIZED_SIGNATORIES: "Change Authorised Signatories",
    MODAL_PAYMENT_PINCAS_CATEGORY_STAKEHOLDER_DETAILS_ITEM_CHANGE_STAKEHOLDER_ON_ROLE_SHAREHOLDER: "Change {{{field}}} to {{{newValue}}} for shareholder role",
    MODAL_PAYMENT_PINCAS_CATEGORY_STAKEHOLDER_DETAILS_ITEM_CHANGE_STAKEHOLDER_ON_ROLE_DIRECTOR:  "Change {{{field}}} to {{{newValue}}} for director role",
    MODAL_PAYMENT_PINCAS_CATEGORY_STAKEHOLDER_DETAILS_ITEM_CHANGE_STAKEHOLDER_ON_ROLE_UBO: "Change {{{field}}} to {{{newValue}}} for UBO role",
    MODAL_PAYMENT_PINCAS_CATEGORY_STAKEHOLDER_DETAILS_ITEM_CHANGE_STAKEHOLDER_ON_DATA_PROTECTION_CONTACT: "Change {{{field}}} to {{{newValue}}} for Data Protection Contact role",
    MODAL_PAYMENT_PINCAS_CATEGORY_STAKEHOLDER_DETAILS_ITEM_CHANGE_STAKEHOLDER_ON_ROLE_AUTHORISED_SIGNATORY: "Change {{{field}}} to {{{newValue}}} for Authorised Signatory role",
    MODAL_PAYMENT_PINCAS_CATEGORY_STAKEHOLDER_ROLES_ITEM_CHANGE_STAKEHOLDER_ADD_ROLE_DIRECTOR:  "Add new role director",
    MODAL_PAYMENT_PINCAS_CATEGORY_STAKEHOLDER_ROLES_ITEM_CHANGE_STAKEHOLDER_REMOVE_ROLE_DIRECTOR:  "Remove role director",
    MODAL_PAYMENT_PINCAS_CATEGORY_EQUITY: "Update {{{description}}} equity",
    MODAL_PAYMENT_PINCAS_CATEGORY_EQUITY_ITEM_CHANGE_EQUITY_CREATE_SHARE_ISSUANCE_ADD_SHAREHOLDER:"Issue shares to {{{entity.description}}}",
    MODAL_PAYMENT_PINCAS_CATEGORY_EQUITY_ITEM_CHANGE_EQUITY_CREATE_SHARE_ISSUANCE_UPDATE_SHAREHOLDER:"Issue shares to {{{entity.description}}}",
    MODAL_PAYMENT_PINCAS_CATEGORY_EQUITY_ITEM_CHANGE_EQUITY_FULL_TRANSFER_SHARE_ISSUANCE_ADD_SHAREHOLDER: "Full transfer of shares to {{{entity.description}}}",
    MODAL_PAYMENT_PINCAS_CATEGORY_EQUITY_ITEM_CHANGE_EQUITY_FULL_TRANSFER_SHARE_ISSUANCE_UPDATE_SHAREHOLDER: "Full transfer of shares to {{{entity.description}}}",
    MODAL_PAYMENT_PINCAS_CATEGORY_EQUITY_ITEM_CHANGE_EQUITY_PARTIAL_TRANSFER_SHARE_ISSUANCE_ADD_SHAREHOLDER: "Partial transfer of shares to {{{entity.description}}}",
    MODAL_PAYMENT_PINCAS_CATEGORY_EQUITY_ITEM_CHANGE_EQUITY_PARTIAL_TRANSFER_SHARE_ISSUANCE_UPDATE_SHAREHOLDER: "Partial transfer of shares to {{{entity.description}}}",
    MODAL_PAYMENT_PINCAS_CATEGORY_EQUITY_ITEM_CHANGE_EQUITY_CANCEL_SHARE_ISSUANCE_REMOVE_SHAREHOLDER: "Cancel share issuance to {{{entity.description}}}",
    MODAL_PAYMENT_PINCAS_CATEGORY_EQUITY_ITEM_CHANGE_EQUITY_CONVERT_OPTION_GRANT_ADD_SHAREHOLDER: "Convert options into shares for {{{entity.description}}}",
    MODAL_PAYMENT_PINCAS_CATEGORY_EQUITY_ITEM_CHANGE_EQUITY_CONVERT_OPTION_GRANT_UPDATE_SHAREHOLDER: "Convert options into shares for {{{entity.description}}}",
    MODAL_PAYMENT_PINCAS_CATEGORY_EQUITY_ITEM_CHANGE_EQUITY_CONVERT_CONVERTIBLE_ADD_SHAREHOLDER: "Convert convertibles into shares for {{{entity.description}}}",
    MODAL_PAYMENT_PINCAS_CATEGORY_EQUITY_ITEM_CHANGE_EQUITY_CONVERT_CONVERTIBLE_UPDATE_SHAREHOLDER: "Convert convertibles into shares for {{{entity.description}}}",
    MODAL_PAYMENT_PINCAS_CATEGORY_EQUITY_ITEM_CHANGE_EQUITY_EXERCISE_WARRANT_ADD_SHAREHOLDER: "Exercise warrant into shares for {{{entity.description}}}",
    MODAL_PAYMENT_PINCAS_CATEGORY_EQUITY_ITEM_CHANGE_EQUITY_EXERCISE_WARRANT_UPDATE_SHAREHOLDER: "Exercise warrant into shares for {{{entity.description}}}",
    MODAL_PAYMENT_PINCAS_CATEGORY_COMPANY_DETAILS_ITEM_CHANGE_COMPANY_ACCOUNTING_REFERENCE_DATE: "Change accounting reference date",
    MODAL_PAYMENT_PINCAS_CATEGORY_COMPANY_DETAILS_ITEM_CHANGE_COMPANY_AMEND_CLARA_NOMINEE: "Use Clara's Nominee authorised signatory service",
    MODAL_PAYMENT_PINCAS_CATEGORY_COMPANY_DETAILS_ITEM_CHANGE_COMPANY_NOMINEE_AUTHORISED_SIGNATORY_SERVICE: "Use Clara's Nominee Authorised Signatory Service",

}

export default US;

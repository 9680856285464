import useGetGroupCompanyAndStakeholders from './graphql/useGetGroupCompanyAndStakeholders';
import useSession from 'src/modules/session/hooks/useSession';
import useGetPINCAPrices from 'src/views/modules/startups/renewal/pincasPrice/queries/useGetPINCAPrices';
import useUpsertRenewal from './graphql/useUpsertRenewalData'
import useUpsertGroupCompany from './graphql/useUpsertGroupCompany';
const useServices = ({ groupCompanyId }) => {

  const { query } = useGetGroupCompanyAndStakeholders({});
  const updateRenewal = useUpsertRenewal();
  const updateGroupCompany = useUpsertGroupCompany();
  const { startupId } = useSession();
  const { manualQuery: getPINCAPrices } = useGetPINCAPrices(
    {
      variables: {
        filters: {
          jurisdictionTypes: ['ADGM']
        }
      }
    }
  );

  const initialData = async () => {
    const data = await query({ startupId, groupCompanyId });
    const stakeholders = data?.stakeholders;
    const groupCompany = data?.groupCompany;
    const designatedContact = groupCompany?.keyPeople?.designatedContact;
    return {
      groupCompany,
      stakeholders,
      designatedContact
    }
  }

  const pincasData = async () => {
    const pincasData = await getPINCAPrices();
    return { pincasData };
  }  

  const saveNoNewDesignatedContact = async (context) => {
    await updateRenewal(groupCompanyId)
    return;
  }
  
  const saveNewDesignatedContact = async (context) => {
    const { SelectDesignatedContact } = context;
    await updateRenewal(groupCompanyId)
    await updateGroupCompany(groupCompanyId,SelectDesignatedContact.stakeholder.id)
    return;
  }

  return {
    initialData,
    pincasData,
    saveNoNewDesignatedContact,
    saveNewDesignatedContact
  }
}
export default useServices;

const useGuards = () => {

  const isChange = (context) => {
    return context.ReviewDesignatedContact.isChange;
  };

  const isNotChange = (context) => {
    return !isChange(context);
  };
  
  return {
    isChange,
    isNotChange
  };
}

export default useGuards;

import { createMachine } from "xstate";
const machine =
  createMachine({
    id: 'ReviewDesignatedContact',
    initial: 'InitialData',
    states: {
      InitialData: {
        invoke: {
          src: 'initialData',
          id: 'initialData',
          onDone: [
            {
              target: 'pincasData',
              actions: ['setContextAfterInvoke']
            }
          ],
          onError: [
            {
              target: 'cancel'
            }
          ]
        }
      },
      pincasData: {
        invoke: {
          src: 'pincasData',
          id: 'pincasData',
          onDone: [
            {
              target: 'ReviewDesignatedContact',
              actions: ['setContextAfterInvoke']
            }
          ],
        }
      },
      ReviewDesignatedContact: {
        on: {
          NEXT: {
            target: 'EvaluateChangeDesignatedContact',
            actions: ['setContextAfterStep']
          },
          CANCEL: {
            target: 'finish'
          }
        }
      },
      EvaluateChangeDesignatedContact: {
        always: [
          {
            cond: 'isChange',
            target: 'SelectDesignatedContact'
          },
          {
            cond: 'isNotChange',
            target: 'SaveNoNewDesignatedContact'
          },
          {
            target: 'cancel'
          },
        ]
      },
      SelectDesignatedContact: {
        on: {
          NEXT: {
            target: 'SaveNewDesignatedContact',
            actions: ['setContextAfterStep']
          },
          PREV: {
            target: 'ReviewDesignatedContact'
          },
          CANCEL: {
            target: 'finish'
          }
        }
      },
      SaveNoNewDesignatedContact: {
        invoke: {
          src: 'saveNoNewDesignatedContact',
          id: 'saveNoNewDesignatedContact',
          onDone: [
            {
              target: 'finish'
            }
          ],
          onError: [
            {
              target: 'finish'
            }
          ]
        }
      },
      error: {
        on: {
          PREV: {
            target: 'ReviewDesignatedContact'
          }
        }
      },
      SaveNewDesignatedContact: {
        invoke: {
          src: 'saveNewDesignatedContact',
          id: 'saveNewDesignatedContact',
          onDone: [
            {
              target: 'finish'
            }
          ],
          onError: [
            {
              target: 'finish'
            }
          ]
        }
      },
      cancel: {
        entry: 'cancelMachine',
        type: 'final'
      },
      finish: {
        entry: 'stopMachine',
        type: 'final'
      }
    }
  });
export default machine;
